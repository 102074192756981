import React, { useState, useEffect } from "react";
import Web3 from "web3";
import { Select } from "antd";
import "./Kangaroo.css";

const Dashboard = () => {
  const [walletAddress, setWalletAddress] = useState("");
  const [unlockBlockNumber, setUnlockBlockNumber] = useState(0);
  const [tokenBalance, setTokenBalance] = useState(0);
  const [bnbSpent, setBnbSpent] = useState(0);
  const [invitedUsersCount, setInvitedUsersCount] = useState(0);
  const [multiplier, setMultiplier] = useState(1);
  const [referrerAddress, setReferrerAddress] = useState(""); // Default to zero address
  // Define inviteLink state
  const [inviteLink, setInviteLink] = useState("");
  const [fullLink, setFullLink] = useState(""); // 新增状态来保存完整链接
  const [users, setUsers] = useState([]);
  const [unlockTimeInSeconds, setUnlockTimeInSeconds] = useState(0); // 用于存储剩余时间的秒数
  const [tokenContractBalance, setTokenContractBalance] = useState(0);
  const [bnbContractBalance, setBnbContractBalance] = useState(0);
  const [tokenPrice, setTokenPrice] = useState(0);
  const [totalValue, setTotalValue] = useState(0);
  const [profit, setProfit] = useState(0);
  const [blockleft, setUnlockBlockLeft] = useState(0);
  const [needinvite, setNeedInviteNumber] = useState(0);
  const [invitedEarnBlock, setInviteEarnBlock] = useState(0);
  const [isEnabled, setIsEnabled] = useState(false); // 添加状态来跟踪是否已经启用
  const [showModal, setShowModal] = useState(false);

  // 点击按钮时切换弹出层的显示状态
  const toggleModal = () => setShowModal((prevShowModal) => !prevShowModal);

  const defaultUsers = [
    { walletAddress: "0x0001***0001", inviteCount: 10 },
    { walletAddress: "0x0002***0002", inviteCount: 5 },
    { walletAddress: "0x0003***0003", inviteCount: 8 },
    { walletAddress: "0x0004***0004", inviteCount: 3 },
    { walletAddress: "0x0005***0005", inviteCount: 12 },
    { walletAddress: "0x0006***0006", inviteCount: 7 },
    { walletAddress: "0x0007***0007", inviteCount: 15 },
    { walletAddress: "0x0008***0008", inviteCount: 4 },
    { walletAddress: "0x0009***0009", inviteCount: 9 },
    { walletAddress: "0x000A***000A", inviteCount: 2 },
  ];

  const mainnet = "Binance Smart Chain";
  const holdingValue = 200; // 示例数据

  // const contractAddress = "0xEFcf8cA4260DdaDF0C0100b40480630700DADd26";
  const contractAddress = "0xE64F227230b99121C3179A68B144e6EE8f983FF1";
  const contractABI = [
    {
      inputs: [
        { internalType: "address", name: "_recipientWallet", type: "address" },
        { internalType: "address", name: "_rewardToken", type: "address" },
        { internalType: "uint256", name: "_tokenRate", type: "uint256" },
      ],
      stateMutability: "nonpayable",
      type: "constructor",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "user",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "totalBNB",
          type: "uint256",
        },
      ],
      name: "BNBRewardUpdated",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "from",
          type: "address",
        },
        { indexed: true, internalType: "address", name: "to", type: "address" },
        {
          indexed: false,
          internalType: "uint256",
          name: "amount",
          type: "uint256",
        },
      ],
      name: "BNBTransferred",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "user",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "inviter",
          type: "address",
        },
      ],
      name: "ReferralRegistered",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: "address", name: "to", type: "address" },
        {
          indexed: false,
          internalType: "uint256",
          name: "tokenAmount",
          type: "uint256",
        },
      ],
      name: "TokenTransferred",
      type: "event",
    },
    {
      inputs: [{ internalType: "address", name: "_user", type: "address" }],
      name: "getInviter",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "_user", type: "address" }],
      name: "getReferralBNB",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "", type: "address" }],
      name: "inviter",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "owner",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "recipientWallet",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "", type: "address" }],
      name: "referralBNB",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "rewardToken",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "_inviter", type: "address" }],
      name: "swapAndRegister",
      outputs: [],
      stateMutability: "payable",
      type: "function",
    },
    {
      inputs: [],
      name: "tokenRate",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "_newWallet", type: "address" },
      ],
      name: "updateRecipientWallet",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "_newRate", type: "uint256" }],
      name: "updateTokenRate",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "withdrawBNB",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "_amount", type: "uint256" }],
      name: "withdrawTokens",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
  ]; // Replace with your contract's ABI

  useEffect(() => {
    const init = async () => {
      if (window.ethereum) {
        const web3 = new Web3(window.ethereum);
        try {
          await window.ethereum.enable();
          setIsEnabled(true); // 设置状态，表示已启用
          const accounts = await web3.eth.getAccounts();
          setWalletAddress(accounts[0]);

          // Set invite link after wallet is connected
          setInviteLink(generateInviteLink(accounts[0]));

          const contract = new web3.eth.Contract(contractABI, contractAddress);

          const bnbSpent = await contract.methods
            .getReferralBNB(accounts[0])
            .call();
          console.log("Invite BNB", bnbSpent);
          setBnbSpent(
            parseFloat(web3.utils.fromWei(bnbSpent, "ether")).toFixed(2)
          );

          const invitedUsersCount = await contract.methods
            .getInvitedUsersCount(accounts[0])
            .call();
          console.log("invitedUsersCount", Number(invitedUsersCount));
          setInvitedUsersCount(Number(invitedUsersCount));

          const balances = await contract.methods.getTokenPriceInBNB().call();
          console.log("balances", balances);
          console.log("bnbAmount", balances[1], "tokenAmount", balances[0]);
          setTokenContractBalance(Number(balances[0]));
          setBnbContractBalance(Number(balances[1]));
        } catch (error) {
          console.error(error);
        }
      }
    };

    init();

    // Parse referrer address from URL if present
    const regex = /ref=([0x0-9a-fA-F]+)/; // 匹配 code 参数
    const matches = window.location.href.match(regex);
    const inviterCode = matches ? matches[1] : null;
    console.log("inviterCode", inviterCode);
    if (inviterCode) {
      setReferrerAddress(inviterCode);
    } else {
      // 如果 inviterCode 为 null，则设置为零地址
      setReferrerAddress(walletAddress);
    }
    console.log("referrerAddress", referrerAddress);
  }, [contractABI, referrerAddress, walletAddress]);

  useEffect(() => {
    const web3 = new Web3(window.ethereum); // 初始化 Web3，确保已连接到钱包

    const fetchUsers = async () => {
      try {
        // 从智能合约获取用户数据的逻辑
        const fetchedUsers = await getUsersFromContract(web3);
        setUsers(fetchedUsers);
      } catch (error) {
        console.error("Failed to fetch users:", error);
        // 如果获取数据失败，使用默认数据
        setUsers(defaultUsers);
      }
    };

    fetchUsers();
  }, []);

  // 获取用户数据的函数
  const getUsersFromContract = async (web3) => {
    try {
      const contract = new web3.eth.Contract(contractABI, contractAddress);
      // 假设有一个方法叫 `getUsers` 返回用户数据
    } catch (error) {
      console.error("Error fetching data from contract:", error);
      return []; // 返回空数组以防止错误
    }
  };

  // 生成邀请链接的函数
  const generateInviteLink = (walletAddress) => {
    const origin = window.location.origin;
    const completeLink = `${origin}/#invite?ref=${walletAddress}`;

    // 如果链接长度大于30，进行截取并使用 ****** 省略
    let displayLink = completeLink;
    if (completeLink.length > 30) {
      const start = completeLink.substring(0, 10); // 前15个字符
      const end = completeLink.substring(completeLink.length - 4); // 后9个字符
      displayLink = `${start}******${end}`;
    }

    // 只返回需要的显示链接
    return displayLink; // 返回字符串而不是对象
  };

  useEffect(() => {
    // 在组件加载时生成邀请链接
    const completeLink = `${window.location.origin}/#invite?ref=${walletAddress}`;
    const displayLink = generateInviteLink(walletAddress);
    setInviteLink(displayLink); // 用于显示的链接
    setFullLink(completeLink); // 设置完整链接的状态
  }, [walletAddress]); // 确保在钱包地址变化时重新生成链接

  // 复制链接的函数
  {/* 
  const copyToClipboard = (copytext) => {
    // 检查 navigator.clipboard 是否可用
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(copytext) // 使用完整链接
        .then(() => {
          console.log("Link copied to clipboard!");
          alert("Your invitation link has been copied to clipboard!");
        })
        .catch((err) => {
          console.error("Failed to copy link: ", err);
          alert("Failed to copy link.");
        });
    } else {
      // 如果 clipboard API 不可用，使用 fallback 方法
      const textarea = document.createElement("textarea");
      textarea.value = copytext;
      document.body.appendChild(textarea);
      textarea.select();
      try {
        document.execCommand("copy");
        console.log("Link copied to clipboard using fallback method!");
        alert("Your invitation link has been copied to clipboard!");
      } catch (err) {
        console.error("Fallback: Failed to copy link: ", err);
        alert("Failed to copy link.");
      } finally {
        document.body.removeChild(textarea);
      }
    }
  };
  // */}

  // {/* 
  // copyToClipboard.js
function copyToClipboard(copytext) {
  if (navigator.clipboard) {
    // 使用异步API复制文本
    navigator.clipboard.writeText(copytext).then(() => {
      console.log("Link copied to clipboard!");
    }).catch((err) => {
      console.error("Failed to copy link: ", err);
    });
  } else {
    // 如果 clipboard API 不可用，使用 fallback 方法
    const textarea = document.createElement("textarea");
    textarea.value = copytext;
    textarea.style.position = 'fixed';  // 防止出现滚动条
    textarea.style.opacity = '0';  // 使textarea不可见
    document.body.appendChild(textarea);
    textarea.focus();
    textarea.select();

    try {
      // 执行复制命令
      document.execCommand("copy");
      console.log("Link copied to clipboard using fallback method!");
    } catch (err) {
      console.error("Fallback: Failed to copy link: ", err);
    } finally {
      document.body.removeChild(textarea);
    }
  }
}

// 绑定按钮点击事件
document.addEventListener('DOMContentLoaded', function() {
  const inviteButton = document.getElementById('invite');
  if (inviteButton) {
    inviteButton.addEventListener('click', function() {
      const inviteLink = 'https://example.com'; // 替换为您想要复制的链接
      copyToClipboard(inviteLink);
      alert('邀请链接已复制到剪贴板！');
    });
  }
});
  // */}



  const handleMaxClick = () => {
    setMultiplier("10"); // 设置最大值为300
  };

  const handleCopyInviteLink = () => {
    // 根据语言选择复制的字符串
    let copyText;
    copyText = `${fullLink}`;
    copyToClipboard(copyText);
  };

  const handlePlay = async () => {
    if (window.ethereum) {
      const web3 = new Web3(window.ethereum);
      try {
        // 检查用户是否已连接钱包
        const accounts = await web3.eth.getAccounts();
        if (accounts.length === 0) {
          console.log("请先连接钱包");
          return; // 提示用户连接钱包
        }

        // 确保只在未启用时请求权限
        if (!isEnabled) {
          await window.ethereum.enable();
          setIsEnabled(true);
        }

        const contract = new web3.eth.Contract(contractABI, contractAddress);

        // 计算要发送的 BNB 金额
        const bnbAmount = web3.utils.toWei(
          (multiplier * 0.1).toString(),
          "ether"
        );
        console.log(`bnbAmount: ${bnbAmount}`);

        // 发送交易到合约
        console.log("referrerAddress", referrerAddress);

        // 调用合约中的 swapAndRegister 函数，传递邀请人地址
        const txHash = await contract.methods
          .swapAndRegister(referrerAddress)
          // 调用合约的 swapAndRegister 方法
          .send({ from: accounts[0], value: bnbAmount, gas: 1500000 });

        console.log(`Transaction hash: ${txHash}`);
      } catch (error) {
        console.error("Error sending transaction:", error);
        // 根据错误类型可以做进一步处理
        if (error.code === 4001) {
          console.log("用户拒绝了请求");
        } else {
          console.log("发生错误:", error.message);
        }
      }
    } else {
      console.log("请安装 Metamask 或其他以太坊钱包");
    }
  };

  return (
    <div className="App">
      <div className="container">
        <div className="top-bar">
          <div className="top-bar-content">
            <a href="http://maotswap.pro" target="_blank" rel="noopener noreferrer">
              <img src="/assets/logo.png" alt="kangaroo Logo" className="logo" />
            </a>
            <div className="middle-empty"></div>
            <button className="connect-btn">
              {walletAddress ? `${walletAddress.slice(0, 6)}...` : "CONNECT"}
            </button>
          </div>
        </div>
        <main className="main">
          <div className="first-m">
            <div className="container-card">
              <div className="card">
                <h1 className="card-title">MAOT IDO</h1>
                <div>
                  <span>
                    <b>Experience New Era In Digital Asset Management</b>
                  </span>
                </div>
                <div className="card-container">
                  <div className="card-left">
                    <div className="card-block">
                      <div className="input-group">
                        <label htmlFor="multiplier">Multi (x0.1 BNB):</label>
                        <Select
                          value={multiplier}
                          style={{ width: 120 }}
                          onChange={(value) => setMultiplier(value)}
                          options={[...Array(10).keys()].map((x) => ({
                            value: x + 1,
                            label: `${x + 1}`,
                          }))}
                          getPopupContainer={(trigger) => trigger.parentNode} // 将弹出框限制在触发元素的父容器中
                        />
                        <button
                          className="connect-btn-small"
                          onClick={handleMaxClick}
                        >
                          Max
                        </button>
                      </div>
                      {/*}
                      <button className="playnow-button" onClick={handlePlay}> */}
                      <button className="playnow-button">
                        Join
                      </button>
                    </div>
                  </div>
                </div>
                <div className="card-span">
                  <span>
                    1. 0.1 BNB = 100000 Maot, you can redeem up to 1bnb
                  </span>
                </div>
                <div className="card-span">
                  <span>
                    2. Invite users to refer to IDO, and recommend
                    second-generation users to reach 10bnb to be rewarded with
                    one NFT (70 copies in total)
                  </span>
                </div>
              </div>
            </div>
          </div>
        </main>
        <main className="main">
          <div className="first-m">
            <div className="container-card">
              <div className="card">
                <h1 className="card-title">Invite & Join MaotSwap</h1>
                <div className="card-container">
                  <div className="card-left">
                    <div className="card-block">
                      <div className="button-group">
                        <a
                          href={fullLink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {inviteLink}
                        </a>
                        <button
                          className="connect-btn-small"
                          onClick={() => handleCopyInviteLink("EN")}
                        >
                          Invite
                        </button>
                      </div>
                      <p>
                        <strong>Your Invite BNB: {bnbSpent}</strong>
                      </p>
                      <p>
                        <strong>Your NFT Gift: {bnbSpent > 10 ? Math.floor(bnbSpent / 10) : 0}</strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="button-group">
            <a
              href="https://bscscan.com/address/0xE64F227230b99121C3179A68B144e6EE8f983FF1"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contract: 0xe64f****3ff1
            </a>
          </div>
          <div className="button-group">
            <a
              href="https://bscscan.com/token/0xb30790bCfB24EDC778A651a0AE4C9a2f13E8cB8e"
              target="_blank"
              rel="noopener noreferrer"
            >
              MAOT: 0xb307****cb8e
            </a>
          </div>
          <div className="first-b">
            {/* 
              <a href="https://x.com/ggh2021a?s=21" target="_blank" className="social">
                <img src='/assets/x.png'></img>
              </a>
              <a href="https://t.me/FomoPanda" target="_blank" className="social">
                <img src="/assets/telegram.png"></img>
              </a>
            */}
          </div>
          <img src="/assets/line.png" alt="" className="line"></img>
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
